import { IconParkSolidCorrect } from '@/assets/icons/comm/IconParkSolidCorrect';
import { TeenyiconsDownSolid } from '@/assets/icons/comm/TeenyiconsDownSolid';
import BaseModal from '@/components/base/baseModal';
import { useDisclosure } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { Dispatch, ReactNode, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default ({
  children,
  options,
  onChange,
  current,
  title,
}: {
  children?: ReactNode;
  options: {
    text: string;
    value?: any;
  }[];
  onChange: (value: { text: string; value?: any ;Icon?: any;  desc?: string;}) => void | Dispatch<
    SetStateAction<{
      text: string;
      value: undefined;
      Icon?: any; // 图标
      desc?: string; // 描述
    }>
  >;
  current: {
    text: string;
    value?: any;
    Icon?: any; // 图标
    desc?: string; // 描述
  };
  title: string;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const { isMobile } = useModel('system');
  const [isFirst, setIsFirst] = useState(true);

  const renderTitle = useMemo(() => {
    return isFirst && current?.value === undefined ? title : current?.text;
  }, [current]);
  return (
    <>
      <div onClick={onOpen}>  
         <span
          className="filterBox rounded-md"
        >
          <div className="cursor-pointer">{renderTitle}</div>
          <TeenyiconsDownSolid className="ml-1" width="0.5em" height="0.8em" />
        </span></div>

      <BaseModal isOpen={isOpen} onClose={onClose} title={title}>
        <div className="px-4 pb-6 flex flex-col gap-3 pt-4">
          {options &&
            options.length &&
            options.map((item: any) => {
              return (
                <>
                  <div
                    className="border-1 rounded-md border-borderColor p-4 flex items-center gap-3 flex justify-between cursor-pointer"
                    onClick={(e) => {
                      onChange(item);
                      setIsFirst(false);
                      onClose();
                      e.stopPropagation();
                    }}
                  >
                    <div className='flex gap-x-3 items-center'>
                                          <item.Icon className="h-[32px] w-[32px]" />
                    <div className="flex flex-col gap-1">
                      <div className="text-[14px] leading-[20px] text-backContrastColor">
                        {item.text}
                      </div>
                      {item?.desc && (
                        <div className="text-[12px] text-auxiliaryTextColor">
                          {t('向您的账户充值数字货币')}
                        </div>
                      )}
                    </div>
                    </div>

                    {current?.value === item.value && (
                      <IconParkSolidCorrect className="text-primary" />
                    )}
                  </div>
                </>
              );
            })}
        </div>
      </BaseModal>
    </>
  );
};
