import { FundsOrderHistoryEnum, WalletTypeEnum } from '@/enums/businessEnum';
import useConstants from '@/pages/funds/orderHistory/index/useConstants';
import RenderUtil from '@/utils/RenderUtil';
import { useModel } from '@umijs/max';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  const transferAccount: any = {
    [WalletTypeEnum.COIN]: t('现货账户'),
    [WalletTypeEnum.FIAT]: t('股票账户'),
    [WalletTypeEnum.CONTRACT]: t('合约账户'),
  };
  const { sourceOptions } = useConstants();
  const { upDownColorConfig } = useModel('system');

  // 收入
  const incomeColor =
    upDownColorConfig === 'upGreen' ? 'text-successColor' : 'text-errorColor';
  // 支出
  const expenditureColor =
    upDownColorConfig === 'upGreen' ? 'text-errorColor' : 'text-successColor';
  const getInfoArray = (item: any, isJumpDetail?: boolean) => {
    return [
      {
        lab: t('金额'),
        value:
          item?.operateDirection === 0
            ? `-${RenderUtil.FormatAmount(item?.operateBalance, 8)}`
            : `+${RenderUtil.FormatAmount(item?.operateBalance, 8)}`,
        labExtraClass: '',
        valueExtraClass: `${
          item?.operateDirection === 0 ? expenditureColor : incomeColor
        } flex-shrink-0`,
      },

      item?.sourceType === FundsOrderHistoryEnum.CONTRACT_TRANSFER &&
      isJumpDetail
        ? {
            lab: t('划转账户'),
            value: transferAccount?.[item?.walletType],
            labExtraClass: '',
            valueExtraClass: '',
          }
        : null,
      !!item?.orderInfo?.name && isJumpDetail
        ? {
            lab: t('其余信息'),
            value: item?.orderInfo?.name,
            labExtraClass: '',
            valueExtraClass: '',
          }
        : null,
      !!item?.coinName && isJumpDetail
        ? {
            lab: t('其余信息'),
            value: item?.coinName,
            labExtraClass: '',
            valueExtraClass: '',
          }
        : null,
      {
        lab: t('来源'),
        value: sourceOptions?.find(
          (i: any) => Number(i.value) === Number(item?.sourceType),
        )?.text,
        labExtraClass: '',
        valueExtraClass: '',
      },
      isJumpDetail
        ? {
            lab: t('手续费'),
            value: RenderUtil.FormatAmount(item?.fee, 2),

            labExtraClass: '',
            valueExtraClass: '',
          }
        : null,
      // isJumpDetail
      //   ? {
      //     lab: t('交易前金额'),
      //     value: RenderUtil.FormatAmount(item?.beforeBalance, 2),
      //     labExtraClass: 'flex-shrink-0',
      //     valueExtraClass: '!text-errorColor',
      //   }
      //   : null,
      // isJumpDetail
      //   ? {
      //     lab: t('交易后金额'),
      //     value: RenderUtil.FormatAmount(item?.afterBalance, 2),
      //     labExtraClass: 'flex-shrink-0',
      //     valueExtraClass: '!text-successColor',
      //   }
      //   : null,
      {
        lab: t('创建时间'),
        value: RenderUtil.formatDate(item?.createTime,"YYYY-MM-DD HH:mm:ss"),
        labExtraClass: '',
        valueExtraClass: '',
      },
    ]?.filter((i) => i !== null);
  };
  return {
    getInfoArray,
  };
};
