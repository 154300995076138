import useConstants from '@/pages/funds/orderHistory/index/useConstants';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import RenderUtil from '@/utils/RenderUtil';

export default function FeaturesFundingRecordsCard({
                                                     item,
                                                     children,
                                                     clickOnTheCard,
                                                     isRight = false,
                                                     isReply = false,
                                                   }: IProps) {
  const { t } = useTranslation();
  const { sourceOptions } = useConstants();

  const successColor = isRight ? 'text-[#009A29]' : 'text-successColor';
  const textColor = useMemo(() => {
    if (isRight && !isReply) {
      return 'text-[#1D2129]';
    } else if (isReply) {
      return 'text-[#4E5969]';
    } else {
      return 'text-auxiliaryTextColor';
    }
  }, [isRight, isReply]);
  return (
    <div
      key={item}
      onClick={() => {
        clickOnTheCard && clickOnTheCard(item);
      }}
    >
      <div className="flex justify-between text-backContrastColor items-center text-sm mb-1">
        <div><span className="mr-1">{item?.amount}</span>{item?.sourceCoinName}</div>
        <div><span className="mr-1">{item?.targetAmount}</span>{item?.targetCoinName}</div>
      </div>
      <div className="flex justify-between text-xs items-center mt-2">
        <span className="text-auxiliaryTextColor">{t('手续费')}</span>
        <span className="flex items-center">
                    <span className="text-backContrastColor text-xs">
                      {item?.fee.toFixed(8)}
                    </span>
          </span>
      </div>
      <div className="flex justify-between text-xs items-center h-[30px]">
        <span className="text-auxiliaryTextColor">{RenderUtil.formatDate(item?.createTime,"YYYY-MM-DD HH:mm:ss")}</span>
        <span className="flex items-center">
                    <span className="text-successColor text-4xl">·</span>
                    <span className="text-auxiliaryTextColor ml-[2px] pt-1">{t('成功')}</span>
                </span>
      </div>
      {children}
    </div>
  );
}

interface IProps {
  item: any;
  clickOnTheCard?: (data: any) => void;
  children?: ReactNode;
  isRight?: boolean;
  isReply?: boolean;
}
