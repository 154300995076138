import { AntDesignFilterFilled } from '@/assets/icons/funds/AntDesignFilterFilled';
import useConstants from '@/pages/funds/orderHistory/index/useConstants';
import { useEffect, useMemo, useState } from 'react';
import { FeaturesDateTime } from '@/components/features/featuresDateTime';
import {  Divider, useDisclosure } from '@nextui-org/react';
import { usePaginationPage } from '@/hooks/usePaginationPage';
import useUrlState from '@ahooksjs/use-url-state';
import PullToRefresh from '@/components/base/basePullToRefresh';
import FeaturesOrderHistoryFlashExchange from '@/components/features/featuresOrderHistoryFlashExchange'
import { history } from '@@/core/history';
import PageEnum from '@/enums/pageEnum';
import { getFlashRedemptionRecordApi } from '../../../../../services/api/requestApi';

export default function FlashRedemptionRecordList({ setLoadingDisplay }: any) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    directionOption,
  } = useConstants();
  const [urlState] = useUrlState<any>({});
  const {
    walletType,
    coinId,
  } = urlState || {};

  const [direction, setDirection] = useState(directionOption[0]);
  const [time, setTime] = useState<string[]>([]);
  const [pageParams, setPageParams] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  // 请求参数(依赖更新,分页hooks会自动发起请求)
  const params = useMemo(() => {
    return {
      startTime: time.length > 0 ? encodeURI(time[0] + 'T00:00:00') : undefined,
      endTime: time.length > 0 ? encodeURI(time[1] + 'T23:59:59') : undefined,
      walletType,
      sourceCoinId: coinId,
      ...pageParams,
    };
  }, [ time, walletType,  coinId, pageParams, direction]);
  //闪兑列表
  const {
    list,
    loading,
    loadMore,
    error,
    total,
    ...flashRedemptionRecordPageProps
  } = usePaginationPage({
    fn: getFlashRedemptionRecordApi,
    params,
    setParams: setPageParams,
  });
      // 更新监听
      useEffect(()=>{
        if(setLoadingDisplay){
          setLoadingDisplay?.(loading)
        }
      },[loading])
  return <div>
    <div className="flex justify-between relative pb-8">
      <div className="flex">
      </div>
      <AntDesignFilterFilled
        className="text-iconFontColor sm:cursor-pointer text-base absolute right-0"
        onClick={onOpen}
      />
    </div>
    {/*列表*/}
    <PullToRefresh
      loadMore={loadMore}
      total={total}
      list={list}
      error={error}
      loading={loading}
      loadingDisplay={false}
      {...flashRedemptionRecordPageProps}
    >
      {list.map((item: any) => {
        return (
          <div key={item?.id}>
            <FeaturesOrderHistoryFlashExchange
              item={item}
              clickOnTheCard={(data) => {
                history.push(
                  `${PageEnum.CONTRACT_ORDER_DETAIL}/${data?.id}?type=flashExchange`,
                );
              }}
            >
              <Divider className="my-4 !bg-backgroundAuxiliaryColor"/>
            </FeaturesOrderHistoryFlashExchange>
          </div>
        );
      })}
    </PullToRefresh>
    {/*时间弹窗*/}
    <FeaturesDateTime
      open={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={(value: any) => {
        const { start, end } = value;
        setTime([start, end]);
        onClose();
      }}
    />
  </div>;
}