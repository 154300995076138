import { AntDesignFilterFilled } from '@/assets/icons/funds/AntDesignFilterFilled';
import { useTranslation } from 'react-i18next';
import useConstants from '@/pages/funds/orderHistory/index/useConstants';
import { useEffect, useMemo, useState } from 'react';
import { FeaturesDateTime } from '@/components/features/featuresDateTime';
import { Button, Divider, useDisclosure } from '@nextui-org/react';
import { usePaginationPage } from '@/hooks/usePaginationPage';
import { fundsListApi } from '@/services/api/requestApi';
import useUrlState from '@ahooksjs/use-url-state';
import PullToRefresh from '@/components/base/basePullToRefresh';
import FeaturesFundingRecordsCard from '@/pages/funds/orderHistory/index/components/FeaturesFundingRecordsCard';
import { CompletedOrderOrderProcessEnum, FundsOrderHistoryEnum} from '@/enums/businessEnum';
import { history } from '@@/core/history';
import PageEnum from '@/enums/pageEnum';
import { PhCopyFill } from '@/assets/icons/mine/PhCopyFill';
import { FormatUtils } from '@/utils/format';

export default function TransferRecordList({ sendFn ,setLoadingDisplay}: any)  {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    directionOption,
  } = useConstants();
  const [urlState] = useUrlState<any>({});
  const {
    walletType,
    coinId,
  } = urlState || {};

  const [direction, setDirection] = useState(directionOption[0]);
  const [sourceType, setSourceType] = useState({
    text: t('全部'),
    value: undefined,
  });
  const [time, setTime] = useState<string[]>([]);
  const [pageParams, setPageParams] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  // 请求参数(依赖更新,分页hooks会自动发起请求)
  const params = useMemo(() => {
    return {
      direction: direction?.value,
      startTime: time.length > 0 ? encodeURI(time[0] + 'T00:00:00') : undefined,
      endTime: time.length > 0 ? encodeURI(time[1] + 'T23:59:59') : undefined,
      walletType,
      coinId,
      sourceType: FundsOrderHistoryEnum.CONTRACT_TRANSFER,
      sourceCoinId: coinId,
      ...pageParams,
    };
  }, [sourceType, time, walletType,  coinId, pageParams, direction]);
  const {
    list,
    loading,
    loadMore,
    error,
    total,
    ...fundingRecordsPageProps
  } = usePaginationPage({
    fn: fundsListApi,
    params,
    setParams: setPageParams,
  });
    // 更新监听
    useEffect(()=>{
      if(setLoadingDisplay){
        setLoadingDisplay?.(loading)
      }
    },[loading])
  return <div>
    <div className="flex justify-between relative pb-8">
      <div className="flex">
      </div>
      <AntDesignFilterFilled
        className="text-iconFontColor sm:cursor-pointer text-base absolute right-0"
        onClick={onOpen}
      />
    </div>
    {/*列表*/}
    <PullToRefresh
      loadMore={loadMore}
      total={total}
      list={list}
      error={error}
      loading={loading}
      loadingDisplay={false}
      {...fundingRecordsPageProps}
    >
      {list.map((item: any) => {
        return (
          <div key={item?.id}>
            <FeaturesFundingRecordsCard
              item={item}
              clickOnTheCard={(data) => {
                // 手续费无详情
                if (+data?.feeMark !== 0) return;
                if (
                  data?.sourceType ===
                  FundsOrderHistoryEnum.CONTRACT_TRANSFER
                ) {
                  history.push(
                    `${PageEnum.CONTRACT_ORDER_DETAIL}/${data?.id}?type=transfer`,
                  );
                  return;
                }
              }}
            >
              {
                sendFn && <>
                  <div className="flex justify-between mt-2 text-xs text-right">
                  <span className=" text-auxiliaryTextColor">
                    {t('订单号')}
                  </span>
                    <span className="text-auxiliaryTextColor flex items-center">
                    {item?.orderInfo?.no}{' '}
                      <PhCopyFill
                        onClick={() => FormatUtils.copyText(item?.orderInfo?.no)}
                        className="text-base ml-1"
                      />
                  </span>
                  </div>
                  <Button
                    className="mainColorButton !h-[30px] !py-0 mt-2"
                    onClick={() => {
                      sendFn({
                        type: CompletedOrderOrderProcessEnum.ALL,
                        id: item?.id,
                      });
                    }}
                  >
                    {t('发送')}
                  </Button>
                  <Divider className="my-4 !bg-backgroundAuxiliaryColor" />
                </>
              }
              <Divider className="my-4 !bg-backgroundAuxiliaryColor" />
            </FeaturesFundingRecordsCard>
          </div>
        );
      })}
    </PullToRefresh>
    {/*时间弹窗*/}
    <FeaturesDateTime
      open={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={(value: any) => {
        const { start, end } = value;
        setTime([start, end]);
        onClose();
      }}
    />
  </div>;
}