import { FundsOrderHistoryEnum, WalletTypeEnum } from '@/enums/businessEnum';
import useConstants from '@/pages/funds/orderHistory/index/useConstants';
import RenderUtil from '@/utils/RenderUtil';
import { useModel } from '@@/exports';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function FeaturesFundingRecordsCard({
  item,
  children,
  clickOnTheCard,
  isRight = false,
  isReply = false,
}: IProps) {
  const { t } = useTranslation();
  const { sourceOptions } = useConstants();
  const { upDownColorConfig } = useModel('system');
  // 收入
  const incomeColor =
    upDownColorConfig === 'upGreen' ? 'text-successColor' : 'text-errorColor';
  // 支出
  const expenditureColor =
    upDownColorConfig === 'upGreen' ? 'text-errorColor' : 'text-successColor';
  const textColor = useMemo(() => {
    if (isRight && !isReply) {
      return 'text-backContrastColor';
    } else if (isReply) {
      return 'text-foreground';
    } else {
      return 'text-auxiliaryTextColor';
    }
  }, [isRight, isReply]);

  return (
    <div
      key={item}
      onClick={() => {
        clickOnTheCard && clickOnTheCard(item);
      }}
    >
      <div className="flex justify-between">
        <span className={`${isReply ? 'text-backContrastColor' : ''} text-sm`}>
          {item?.feeMark == 0
            ? sourceOptions?.find(
                (i: any) => Number(i.value) === Number(item?.sourceType),
              )?.text
            : t('手续费')}
        </span>
        {/*币币订单*/}
        {item?.operateDirection === 0 && (
          <span className={`${expenditureColor} flex-shrink-0`}>
            {/* 邀请返佣【sourceType==500】2位 */}
            -{RenderUtil.FormatAmount(item?.operateBalance, Number(item?.sourceType)===500 ?2:8, false)}
          </span>
        )}
        {item?.operateDirection === 1 && (
          <span className={`${[0,null,undefined].includes(item?.operateBalance)?'':incomeColor} flex-shrink-0`}>
            {/* 邀请返佣【sourceType==500】2位 */}
            {[0,null,undefined].includes(item?.operateBalance)?"":"+"}{RenderUtil.FormatAmount(item?.operateBalance, Number(+item?.sourceType)===500 ?2:8, false)}
          </span>
        )}
      </div>
      <div className="flex justify-between mt-2 text-xs">
       <span className={`${textColor}`}>
                 {/*普通订单表单*/}
         {
           +item?.walletType === +WalletTypeEnum.CONTRACT && item?.sourceType !== FundsOrderHistoryEnum.CONTRACT_TRANSFER &&
           <span>{item?.orderInfo?.name}</span>
         }
         {
           +item?.walletType === +WalletTypeEnum.CONTRACT && item?.sourceType === FundsOrderHistoryEnum.CONTRACT_TRANSFER &&
           <span>{item?.coinAlias}</span>
         }
         {
           +item?.walletType !== +WalletTypeEnum.CONTRACT &&
           <span>{item?.coinAlias ?? item?.orderInfo?.name}</span>
         }

         {/*  划转的标题*/}
         {item?.walletType === WalletTypeEnum.COIN &&
           item?.sourceType === FundsOrderHistoryEnum.CONTRACT_TRANSFER && (
             <span>{t('现货账户')}</span>
           )}
         {item?.walletType === WalletTypeEnum.CONTRACT &&
           item?.sourceType === FundsOrderHistoryEnum.CONTRACT_TRANSFER && (
             <span>{t('合约账户')}</span>
           )}
         {item?.walletType === WalletTypeEnum.FASTCONTRACT &&
           item?.sourceType === FundsOrderHistoryEnum.CONTRACT_TRANSFER && (
             <span>{t('快速合约账户')}</span>
           )}
        </span>
      </div>
      <div className="flex justify-between mt-2 text-xs text-right">
        <span className={`${textColor} flex-shrink-0`}>{t('创建时间')}</span>
        <span className={`${textColor} `}>
          {RenderUtil.formatDate(item?.createTime, "YYYY-MM-DD HH:mm:ss")}
        </span>
      </div>
      {children}
    </div>
  );
}

interface IProps {
  item: any;
  clickOnTheCard?: (data: any) => void;
  children?: ReactNode;
  isRight?: boolean;
  isReply?: boolean;
}
